.dark {
  background-color: #222;
  color: #e6e6e6;
}

.dark {
  mark {
    color: #222;
    background-color: #e6e6e6;
  }

  a {
    color: #477dca;
  }
}
