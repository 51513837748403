body {
  color: #333;
  font-family: Roboto, sans-serif;
  font-feature-settings: 'kern', 'liga', 'pnum';
  font-size: 1rem;
  line-height: 1.5;

  @media print {
    font-size: 0.9rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  line-height: 1.2;
  margin: 0 0 0.75rem;
}

p {
  margin: 0 0 0.75rem;
}

a {
  color: #477dca;
  transition: color 150ms ease;
}
a:active,
a:focus,
a:hover {
  color: #355e98;
}

h1 {
  font-size: 4rem;
  margin-bottom: 0.1rem;
  font-weight: 500;
}

h2 {
  font-weight: 300;
  font-size: 2rem;
}

h3 {
  font-weight: 300;
  font-size: 3rem;
  text-align: center;
  border-bottom: dashed 2px #cccccc;
  padding-bottom: 10px;
  margin-bottom: 40px;

  @media print {
    margin-bottom: 20px;
  }
}

h4 {
  font-weight: 300;
  font-size: 2.5rem;
  margin-bottom: 3px;
}

p,
ul {
  font-size: 1.6rem;
  text-align: justify;
}

a i {
  font-size: 1.6rem;
}

mark {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
